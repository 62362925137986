// @ts-nocheck
import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";
import Globe from "globe.gl";
import SpriteText from "three-spritetext";
import { Texture, Sprite, Material } from "three";
import { cloneDeep } from "lodash";

import bubble1 from "../img/bubble1.png";
import bubbleRsvp from "../img/bubbleRsvp.png";
import discordChatField from "../img/discord_chat_field.png";

import earthNight from "../img/earth-night.jpg";
import earthTopology from "../img/earth-topology.png";

interface IResponderDefinition {
	lng: number;
	lat: number;
	emoji: string;
}

interface IHostDefinition {
	lng: number;
	lat: number;
	responders: Array<IResponderDefinition>;
	emoji: string;
}

interface IGlobeObject {
	sprite: THREE.Sprite;
	sprite_scale_x: number;
	sprite_scale_y: number;
	lng: number;
	lat: number;
	altitude: number;
	index: number;
}

const host_definitions: Array<IHostDefinition> = [
	{
		// US
		emoji: "🧑",
		lng: -95.95459,
		lat: 36.155618,
		responders: [
			{ lng: -122.67334, lat: 45.521744, emoji: "👱" },
			{ lng: -73.98674, lat: 40.753499, emoji: "👨‍🦳" },
			{ lng: -106.435547, lat: 31.765537, emoji: "👧" },
		],
	},
	{
		// EU
		emoji: "👩‍🦱",
		lng: 10.744243,
		lat: 44.912725,
		responders: [
			{ lng: -0.1440787, lat: 51.501364, emoji: "💂" },
			{ lng: -3.603516, lat: 37.177826, emoji: "👩‍🦳" },
			{ lng: 21.005859, lat: 52.224435, emoji: "👱‍♀️" },
			{ lng: -7.976074, lat: 31.812229, emoji: "🧔" },
		],
	},
	{
		// South Asia
		emoji: "👨‍🦱",
		lng: 67.17041,
		lat: 25.065697,
		responders: [
			{ lng: 74.267578, lat: 31.625321, emoji: "👩" },
			{ lng: 72.575684, lat: 23.019076, emoji: "🧓" },
		],
	},
	{
		// AUS
		emoji: "👵",
		lng: 138.647461,
		lat: -34.976002,
		responders: [
			{ lng: 144.997559, lat: -37.788081, emoji: "👱‍♂️" },
			{ lng: 151.21582, lat: -33.83392, emoji: "🦘" },
			{ lng: 121.530762, lat: -30.770159, emoji: "👦" },
		],
	},
	{
		// South Korea
		emoji: "🧔",
		lng: 129.067383,
		lat: 35.182788,
		responders: [
			{ lng: 127.001953, lat: 37.588119, emoji: "👵" },
			{ lng: 139.790039, lat: 35.639441, emoji: "👨‍🦲" },
			{ lng: 141.350098, lat: 43.036776, emoji: "👨" },
			{ lng: 116.993408, lat: 33.797409, emoji: "🧑" },
		],
	},
];

const EVENT_PREFIX = "/create";
let EVENT_CREATIONS = [
	"Apex at 5pm",
	"CS:GO tomorrow at 6pm",
	"Minecraft in 2 hours",
	"DND 7/18 at noon",
	"Halo saturday at 8pm",
	"PUBG Tuesday at 7pm",
	"Fortnite in a fortnight",
	"Dance party in 23 seconds",
];

const BUBBLE_X_PADDING = 40;
const BUBBLE_Y_PADDING = 125;
const PERSON_Y_PADDING = 50;

const DISCORD_FIELD_X_PADDING = 25;
const DISCORD_FIELD_Y_PADDING = 20;
const DISCORD_FIELD_CORNER_RADIUS_HEIGHT_RELATIVE = 0.5;
// Should be a power of 2
const DISCORD_FIELD_SPRITE_WIDTH = 512;
const DISCORD_FIELD_SPRITE_HEIGHT = 128;

const SCALE_FACTOR = 0.8;

const RSVP_SCALE = 0.06 * SCALE_FACTOR;
const CREATOR_SCALE = 0.1 * SCALE_FACTOR;
const TEXT_SCALE = 0.12 * SCALE_FACTOR;

const BUBBLE_SCALE_CONTROLLER = 0.2 * SCALE_FACTOR;
const BUBBLE_Y_OFFSET_CONTROLLER = -0.27 * SCALE_FACTOR;

const BUBBLE_SCALE_RSVP = 0.1 * SCALE_FACTOR;
const BUBBLE_Y_OFFSET_RSVP = -0.32;

const BUBBLE_POP_IN_SCALE = 1.5;
const PERSON_POP_IN_SCALE = 1.25;

const CAMERA_ALTITUDE_DESKTOP = 1.9;
const CAMERA_ALTITUDE_MOBILE = 1.9;

const PATH_TRAVEL_TIME = 2500;

const POV_LERP_TIME = 2000;
const FIRST_HOST_ANIMATION_START_DELAY = 1000;
const ANIMATION_START_DELAY = POV_LERP_TIME;
const FINISHED_TYPING_HOLD_DURATION = 2000;
const RSVPER_POPUP_VARIANCE = 500;
const BUBBLE_POP_IN_DURATION = 500;
const EVENT_TEXT_TYPE_DURATION = 1000;
const TEXT_FIELD_POP_IN_DURATION = 500;

const HOST_BUBBLE_POP_IN_DELAY = 200;
const BUBBLE_FADE_OUT_TIME = 1000;

const DELAY_BEFORE_HOST_SWITCH = 2000;

const GLOBE_HOST_SEQUENCE_DELAY =
	ANIMATION_START_DELAY +
	EVENT_TEXT_TYPE_DURATION +
	FINISHED_TYPING_HOLD_DURATION +
	PATH_TRAVEL_TIME +
	DELAY_BEFORE_HOST_SWITCH;

const TEXTURE_FILTER = THREE.LinearMipmapLinearFilter;
const TYPING_TEXT_FONT_SIZE = 30;

const GLOBE_INTERACTION_COOLDOWN = 3000;

const MOBILE_LAT_OFFSET = 5;
const DESKTOP_LAT_OFFSET = 5;

let animations = new TWEEN.Group();
let globe_switch_animation = new TWEEN.Group();

function getResponsiveCameraAltitude() {
	const is_mobile = isMobile();
	return is_mobile ? CAMERA_ALTITUDE_MOBILE : CAMERA_ALTITUDE_DESKTOP;
}

function getResponsiveLngOffset(globe: any) {
	if (isMobile()) {
		return 0;
	}

	const canvas_ele_bounding_rect = globe
		.renderer()
		.domElement.getBoundingClientRect();
	const body_rect = document.body.getBoundingClientRect();
	const px_off_screen = Math.max(
		canvas_ele_bounding_rect.right - body_rect.right,
		0
	);
	const percentage_off_screen =
		px_off_screen / canvas_ele_bounding_rect.width;

	return percentage_off_screen > 0.25 ? 25 : 0;
}

function isMobile() {
	return window.innerWidth <= 768;
}

function createBubbleSprite(image, scale, center_offset_y, render_order = 0) {
	let texture = new THREE.Texture(image);
	texture.generateMipmaps = true;
	texture.minFilter = TEXTURE_FILTER;

	texture.needsUpdate = true;

	let material = new THREE.SpriteMaterial({ map: texture });

	let sprite = new THREE.Sprite(material);

	sprite.scale.set(scale, scale, 1);

	sprite.material.depthTest = false;
	sprite.material.sizeAttenuation = false;
	sprite.center = new THREE.Vector2(0.5, center_offset_y);
	sprite.renderOrder = render_order;

	sprite.material.opacity = 0;

	return sprite;
}

function createPersonEmojiSprite(personEmoji, scale, render_order) {
	let fontFace = "Arial";
	let fontSize = 200; // defines text resolution
	let fontWeight = "normal";

	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	const font = `${fontWeight} ${fontSize}px ${fontFace}`;

	ctx.font = font; // measure canvas with appropriate font

	let person_height = fontSize + PERSON_Y_PADDING;

	let height = person_height;
	let width = ctx.measureText(personEmoji).width + BUBBLE_X_PADDING * 2;
	canvas.width = getNextPowOf2(width);
	canvas.height = getNextPowOf2(height);

	// Set font again after canvas is resized, as context properties are reset
	ctx.font = font;
	ctx.fillStyle = "rgba(255, 255, 255, 1)";
	ctx.textBaseline = "middle";

	const y = (canvas.height - height) / 2;

	ctx.fillText(
		personEmoji,
		(canvas.width - ctx.measureText(personEmoji).width) / 2,
		y + person_height / 2
	);

	let texture = new THREE.Texture(canvas);
	texture.generateMipmaps = true;
	texture.minFilter = TEXTURE_FILTER;
	texture.needsUpdate = true;

	let material = new THREE.SpriteMaterial({ map: texture });

	let sprite = new THREE.Sprite(material);

	const xScale = (scale * canvas.width) / canvas.height;
	const yScale = scale;
	sprite.scale.set(xScale, yScale, 1);

	sprite.material.depthTest = false;
	sprite.center = new THREE.Vector2(0.5, 0.5);

	sprite.material.sizeAttenuation = false;

	sprite.renderOrder = render_order;

	return sprite;
}

function createEventMessage(scale, camera_altitude) {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	let fontFace = "Arial";
	let fontSize = TYPING_TEXT_FONT_SIZE; // defines text resolution
	let fontWeight = "normal";
	const font = `${fontWeight} ${fontSize}px ${fontFace}`;
	ctx.font = font;
	ctx.textBaseline = "middle";

	canvas.width = DISCORD_FIELD_SPRITE_WIDTH;
	canvas.height = DISCORD_FIELD_SPRITE_HEIGHT;

	// Set font again after canvas is resized, as context properties are reset
	ctx.font = font;
	ctx.textBaseline = "middle";

	let texture = new THREE.Texture(canvas);
	texture.generateMipmaps = true;
	texture.minFilter = TEXTURE_FILTER;

	let material = new THREE.SpriteMaterial({ map: texture });

	let sprite = new THREE.Sprite(material);

	const xScale = (scale * canvas.width) / canvas.height;
	const yScale = scale;
	sprite.scale.set(xScale, yScale, 1);

	sprite.material.depthTest = false;
	sprite.material.sizeAttenuation = false;
	sprite.center = new THREE.Vector2(0.5, 1.25);
	sprite.renderOrder = 400;

	material.opacity = 0;

	texture.needsUpdate = true;
	return sprite;
}

function clearSprite(sprite) {
	let texture = sprite.material.map;
	let canvas = texture.image;
	const ctx = canvas.getContext("2d");
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	texture.needsUpdate = true;
}

function startDrawTypingText(text, sprite, time) {
	let texture = sprite.material.map;
	let canvas = texture.image;
	const ctx = canvas.getContext("2d");

	let height = TYPING_TEXT_FONT_SIZE + DISCORD_FIELD_Y_PADDING * 2;
	let width = ctx.measureText(text).width + DISCORD_FIELD_X_PADDING * 2;

	var rectX = canvas.width / 2 - width / 2;
	var rectY = canvas.height / 2 - height / 2;
	var rectWidth = width;
	var rectHeight = height;
	var cornerRadius = height * DISCORD_FIELD_CORNER_RADIUS_HEIGHT_RELATIVE;

	ctx.lineJoin = "round";
	ctx.lineWidth = cornerRadius;

	ctx.fillStyle = "#40444B";
	ctx.strokeStyle = "#40444B";

	// Change origin and dimensions to match true size (a stroke makes the shape a bit larger)
	ctx.strokeRect(
		rectX + cornerRadius / 2,
		rectY + cornerRadius / 2,
		rectWidth - cornerRadius,
		rectHeight - cornerRadius
	);
	ctx.fillRect(
		rectX + cornerRadius / 2,
		rectY + cornerRadius / 2,
		rectWidth - cornerRadius,
		rectHeight - cornerRadius
	);

	let text_state = {
		complete_alpha: 0,
	};

	let rendered_characters_index = 0;

	texture.needsUpdate = true;

	ctx.fillStyle = "rgba(255, 255, 255, 1)";

	return new TWEEN.Tween(text_state, animations)
		.to({ complete_alpha: 1 })
		.duration(time)
		.onUpdate(function (state) {
			let index = Math.floor(text.length * state.complete_alpha);
			if (index !== rendered_characters_index) {
				rendered_characters_index = index;

				let str_to_print = text.substring(0, index);
				ctx.fillText(
					str_to_print,
					(ctx.canvas.width - ctx.measureText(text).width) / 2,
					ctx.canvas.height / 2
				);

				texture.needsUpdate = true;
			}
		});
}

function tweenSpriteOpacity(
	sprite: THREE.Sprite,
	desiredAlpha: number,
	time: number
) {
	new TWEEN.Tween(sprite.material, animations)
		.to({ opacity: desiredAlpha }, time)
		.start();
}

function tweenSpriteScale(
	sprite: THREE.Sprite,
	startScaleX: number,
	startScaleY: number,
	endScaleX: number,
	endScaleY: number,
	time: number,
	delay: number
) {
	new TWEEN.Tween(sprite.scale, animations)
		.to({ x: endScaleX, y: endScaleY }, time / 2)
		.delay(delay)
		.easing(TWEEN.Easing.Sinusoidal.InOut)
		.onComplete(function () {
			new TWEEN.Tween(sprite.scale, animations)
				.to({ x: startScaleX, y: startScaleY }, time / 2)
				.easing(TWEEN.Easing.Sinusoidal.InOut)
				.onComplete(function () {
					sprite.scale.set(startScaleX, startScaleY, 1);
				})
				.start();
		})
		.start();
}

function popInBubble(labelData, time: number, delay: number = 0) {
	let bubble = labelData.bubble;
	let bubble_sprite = bubble.sprite;
	tweenSpriteOpacity(bubble_sprite, 1, time);

	const startScaleX = bubble.sprite_scale_x;
	const startScaleY = bubble.sprite_scale_y;
	const endScaleX = startScaleX * BUBBLE_POP_IN_SCALE;
	const endScaleY = startScaleY * BUBBLE_POP_IN_SCALE;
	bubble_sprite.scale.set(0.05, 0.05, 1);
	tweenSpriteScale(
		bubble_sprite,
		startScaleX,
		startScaleY,
		endScaleX,
		endScaleY,
		time,
		delay
	);

	let person_sprite = labelData.person.sprite;
	const personScaleX = labelData.person.sprite_scale_x;
	const personScaleY = labelData.person.sprite_scale_y;

	tweenSpriteScale(
		person_sprite,
		personScaleX,
		personScaleY,
		personScaleX * PERSON_POP_IN_SCALE,
		personScaleY * PERSON_POP_IN_SCALE,
		time * 0.5,
		delay
	);
}

function closeTextDialog(textData: IGlobeObject, time: number) {
	let sprite = textData.sprite;
	const startScaleX = textData.sprite_scale_x;
	const startScaleY = textData.sprite_scale_y;
	new TWEEN.Tween(sprite.scale, animations)
		.to({ x: 0.05, y: 0.05 }, time)
		.easing(TWEEN.Easing.Cubic.In)
		.onComplete(() => {
			sprite.scale.x = startScaleX;
			sprite.scale.y = startScaleY;
			sprite.material.opacity = 0;
		})
		.start();
}

function openTextDialog(textData: IGlobeObject, time: number) {
	let sprite = textData.sprite;

	const endScaleX = textData.sprite_scale_x;
	const endScaleY = textData.sprite_scale_y;

	sprite.scale.x = 0.05;
	sprite.scale.y = 0.05;
	sprite.material.opacity = 1;

	new TWEEN.Tween(sprite.scale, animations)
		.to({ x: endScaleX, y: endScaleY }, time)
		.easing(TWEEN.Easing.Cubic.Out)
		.onComplete(() => {
			sprite.scale.x = endScaleX;
			sprite.scale.y = endScaleY;
		})
		.start();
}

let images_awaiting_load = [];
let images = {};
let image_count = 0;
let images_loaded_count = 0;

function loadImage(name, src) {
	let image = new Image();
	image.src = src;
	images[name] = image;

	image.onload = onImageLoad;
}

function getNextPowOf2(x: number) {
	return Math.pow(2, Math.ceil(Math.log2(x)));
}

function onImageLoad() {
	images_loaded_count++;
	if (images_loaded_count >= image_count) {
		initGlobe();
	}
}

function initImage(name, src) {
	images_awaiting_load.push({
		name: name,
		src: src,
	});

	image_count++;
}

function load() {
	initImage("bubbleControllerQuestionMark", bubble1);
	initImage("bubbleRsvp", bubbleRsvp);
	initImage("discord_field", discordChatField);

	for (let imageData of images_awaiting_load) {
		loadImage(imageData.name, imageData.src);
	}
}

function getNextEventCreationText() {
	const text = EVENT_PREFIX + " " + EVENT_CREATIONS[0];
	EVENT_CREATIONS.push(EVENT_CREATIONS.shift());
	return text;
}

function initGlobe() {
	let globe_interaction_timetamp = 0;

	let current_is_mobile = isMobile();

	const element_to_select = current_is_mobile
		? ".globe-mobile"
		: ".globe-desktop";

	const hero_bg_color = "rgba(0, 0, 0, 0)";

	const globe_dom_element = document.querySelector(element_to_select);
	const globe = Globe();

	let camera_altitude = getResponsiveCameraAltitude();

	let globe_objects: Array<IGlobeObject> = [];
	let globe_objects_by_host = [];

	let max_index = 0;

	for (const host_definition of host_definitions) {
		let eventBubbleSprite = createBubbleSprite(
			images["bubbleControllerQuestionMark"],
			BUBBLE_SCALE_CONTROLLER,
			BUBBLE_Y_OFFSET_CONTROLLER,
			500
		);
		let messagePersonSprite = createPersonEmojiSprite(
			host_definition.emoji,
			CREATOR_SCALE,
			1000
		);

		let eventBubble: IGlobeObject = {
			sprite: eventBubbleSprite,
			sprite_scale_x: eventBubbleSprite.scale.x,
			sprite_scale_y: eventBubbleSprite.scale.y,
			lat: host_definition.lat,
			lng: host_definition.lng,
			altitude: 0,
			index: max_index,
		};

		let messagePerson: IGlobeObject = {
			sprite: messagePersonSprite,
			sprite_scale_x: messagePersonSprite.scale.x,
			sprite_scale_y: messagePersonSprite.scale.y,
			lat: host_definition.lat,
			lng: host_definition.lng,
			altitude: 0,
			index: max_index,
		};

		let textSprite = createEventMessage(TEXT_SCALE, camera_altitude);

		let eventText: IGlobeObject = {
			sprite: textSprite,
			sprite_scale_x: textSprite.scale.x,
			sprite_scale_y: textSprite.scale.y,
			lat: host_definition.lat,
			lng: host_definition.lng,
			altitude: 0,
			index: max_index,
		};

		let eventMessage = {
			person: messagePerson,
			bubble: eventBubble,
			text: eventText,
		};

		globe_objects.push(eventBubble);
		globe_objects.push(messagePerson);
		globe_objects.push(eventText);

		let pathsData = [];
		let RSVPs = [];

		for (const responder_definition of host_definition.responders) {
			pathsData.push([
				[host_definition.lat, host_definition.lng, 0],
				[responder_definition.lat, responder_definition.lng, 0],
			]);

			let currentBubble = createBubbleSprite(
				images["bubbleRsvp"],
				BUBBLE_SCALE_RSVP,
				BUBBLE_Y_OFFSET_RSVP,
				250
			);
			let currentPerson = createPersonEmojiSprite(
				responder_definition.emoji,
				RSVP_SCALE,
				100
			);

			let rsvpBubble: IGlobeObject = {
				sprite: currentBubble,
				sprite_scale_x: currentBubble.scale.x,
				sprite_scale_y: currentBubble.scale.y,
				lat: responder_definition.lat,
				lng: responder_definition.lng,
				altitude: 0,
				index: max_index,
			};

			let rsvpPerson: IGlobeObject = {
				sprite: currentPerson,
				sprite_scale_x: currentPerson.scale.x,
				sprite_scale_y: currentPerson.scale.y,
				lat: responder_definition.lat,
				lng: responder_definition.lng,
				altitude: 0,
				index: max_index,
			};

			let RSVP = {
				person: rsvpPerson,
				bubble: rsvpBubble,
			};

			globe_objects.push(rsvpBubble);
			globe_objects.push(rsvpPerson);

			RSVPs.push(RSVP);
		}

		globe_objects_by_host.push({
			message: eventMessage,
			RSVPs: RSVPs,
			pathsData: pathsData,
		});

		max_index++;
	}

	let current_index = 0;

	const starting_lat = host_definitions[0].lat;
	const starting_lng = host_definitions[0].lng;

	globe(globe_dom_element)
		.globeImageUrl(earthNight)
		.bumpImageUrl(earthTopology)
		.backgroundColor(hero_bg_color)
		.customLayerData(globe_objects)
		.customThreeObject((d) => {
			return d.sprite;
		})
		.customThreeObjectUpdate((obj, d) => {
			Object.assign(
				obj.position,
				globe.getCoords(d.lat, d.lng, d.altitude)
			);
		})
		.pathTransitionDuration(PATH_TRAVEL_TIME)
		//.pathStroke(5)
		.pathColor(() => ["rgb(0, 192, 201)", "rgb(255, 192, 201)"]);

	if (isMobile()) {
		const globe_dom_element = document.querySelector(".globe-mobile");

		globe.width(globe_dom_element.clientWidth);
		globe.height(globe_dom_element.clientHeight);
	} else {
		const globe_dom_element = document.querySelector(".globe-desktop");

		globe.width(globe_dom_element.clientWidth);
		globe.height(globe_dom_element.clientWidth);
	}

	let lat_offset = isMobile() ? MOBILE_LAT_OFFSET : DESKTOP_LAT_OFFSET;
	let lng_offset = 0;
	globe.pointOfView({
		lat: starting_lat + lat_offset,
		lng: starting_lng + lng_offset,
		altitude: camera_altitude,
	});

	window.addEventListener("resize", function (event) {
		const is_mobile_now = isMobile();
		if (is_mobile_now != current_is_mobile) {
			const current_element = document.querySelector(
				current_is_mobile ? ".globe-mobile" : ".globe-desktop"
			);
			const future_element = document.querySelector(
				is_mobile_now ? ".globe-mobile" : ".globe-desktop"
			);

			future_element.appendChild(current_element.firstChild);

			current_is_mobile = is_mobile_now;
		}

		if (isMobile()) {
			const globe_dom_element = document.querySelector(".globe-mobile");

			globe.width(globe_dom_element.clientWidth);
			globe.height(globe_dom_element.clientHeight);
		} else {
			const globe_dom_element = document.querySelector(".globe-desktop");

			globe.width(globe_dom_element.clientWidth);
			globe.height(globe_dom_element.clientWidth);
		}

		camera_altitude = getResponsiveCameraAltitude();
		lng_offset = getResponsiveLngOffset(globe);

		globe.pointOfView({
			altitude: camera_altitude,
		});
	});

	let is_first_host_location = true;

	function goToNextHostLocation() {
		let current_obj = globe_objects_by_host[current_index];
		lng_offset = getResponsiveLngOffset(globe);

		tweenSpriteOpacity(
			current_obj.message.bubble.sprite,
			0,
			BUBBLE_FADE_OUT_TIME
		);

		clearSprite(current_obj.message.text.sprite);

		for (let RSVP of current_obj.RSVPs) {
			tweenSpriteOpacity(RSVP.bubble.sprite, 0, BUBBLE_FADE_OUT_TIME);
		}

		current_index++;
		if (current_index >= max_index) {
			current_index = 0;
		}

		let next_obj = globe_objects_by_host[current_index];

		globe.pointOfView(
			{
				lat:
					globe_objects_by_host[current_index].message.person.lat +
					lat_offset,
				lng:
					globe_objects_by_host[current_index].message.person.lng +
					lng_offset,
				altitude: camera_altitude,
			},
			POV_LERP_TIME
		);

		globe.pathsData([]);

		function popUpEventMessage() {
			closeTextDialog(
				next_obj.message.text,
				BUBBLE_POP_IN_DURATION * 0.5
			);
			popInBubble(
				next_obj.message,
				BUBBLE_POP_IN_DURATION,
				HOST_BUBBLE_POP_IN_DELAY
			);

			let pathsData = cloneDeep(next_obj.pathsData);

			globe.pathsData(pathsData);
		}

		function popUpRSVPs() {
			for (let RSVP of next_obj.RSVPs) {
				const randValue = Math.floor(
					Math.random() * RSVPER_POPUP_VARIANCE
				);
				popInBubble(RSVP, BUBBLE_POP_IN_DURATION + randValue);
			}
		}

		let typingTween = startDrawTypingText(
			getNextEventCreationText(),
			next_obj.message.text.sprite,
			EVENT_TEXT_TYPE_DURATION
		);

		const anim_start_delay = is_first_host_location
			? FIRST_HOST_ANIMATION_START_DELAY
			: ANIMATION_START_DELAY;

		// Text field popping in
		new TWEEN.Tween({}, animations)
			.delay(anim_start_delay - TEXT_FIELD_POP_IN_DURATION)
			.onStart(() => {
				openTextDialog(
					next_obj.message.text,
					TEXT_FIELD_POP_IN_DURATION
				);
			})
			.start();

		// Typing out animation
		typingTween
			.delay(anim_start_delay)
			.onComplete(() => {
				// Controller bubble animation
				new TWEEN.Tween({}, animations)
					.delay(FINISHED_TYPING_HOLD_DURATION)
					.onStart(() => {
						popUpEventMessage();
						// pop up rsvp dialog
						new TWEEN.Tween({}, animations)
							.delay(PATH_TRAVEL_TIME)
							.onStart(popUpRSVPs)
							.start();
					})
					.start();
			})
			.start();

		// pop up event bubble dialog
		is_first_host_location = false;
	}

	current_index = max_index - 1;

	goToNextHostLocation();

	new TWEEN.Tween({}, globe_switch_animation)
		.delay(GLOBE_HOST_SEQUENCE_DELAY - ANIMATION_START_DELAY)
		.onComplete(() => {
			let globe_animation_switch = new TWEEN.Tween(
				{},
				globe_switch_animation
			)
				.repeat(Infinity)
				.repeatDelay(GLOBE_HOST_SEQUENCE_DELAY)
				.onRepeat(goToNextHostLocation)
				.start();
		})
		.start();

	const globe_controls = globe.controls();
	globe_controls.enableZoom = false;

	globe_controls.maxPolarAngle = Math.PI * 0.7;
	globe_controls.minPolarAngle = Math.PI * 0.3;
	globe_controls.rotateSpeed = 0.1;

	globe_controls.autoRotate = true;
	globe_controls.autoRotateSpeed = -0.015;

	globe_controls.dampingFactor = 0.1;

	globe_controls.addEventListener("change", () => {
		const start_time = new Date().valueOf();

		const globe_coords = globe.toGeoCoords(globe.camera().position);
		const lat1 = globe_coords.lat;
		const lon1 = globe_coords.lng;

		for (const globe_object of globe_objects) {
			const lat2 = globe_object.lat;
			const lon2 = globe_object.lng;

			var R = 6371e3; // metres
			var φ1 = (lat1 * Math.PI) / 180;
			var φ2 = (lat2 * Math.PI) / 180;
			var Δφ = ((lat2 - lat1) * Math.PI) / 180;
			var Δλ = ((lon2 - lon1) * Math.PI) / 180;

			var a =
				Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
				Math.cos(φ1) *
					Math.cos(φ2) *
					Math.sin(Δλ / 2) *
					Math.sin(Δλ / 2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

			globe_object.sprite.visible = c <= Math.PI / 3;
		}
	});

	let should_update_globe_switch_animation = true;

	function step(timestamp) {
		requestAnimationFrame(step);
		animations.update(timestamp);

		if (globe_controls.isBeingManuallyRotated()) {
			globe_interaction_timetamp = timestamp;
			should_update_globe_switch_animation = false;
		}

		if (!should_update_globe_switch_animation) {
			if (
				timestamp - globe_interaction_timetamp >
				GLOBE_INTERACTION_COOLDOWN
			) {
				should_update_globe_switch_animation = true;
			}
		} else {
			globe_switch_animation.update(timestamp);
		}
	}

	requestAnimationFrame(step);
}

document.addEventListener("DOMContentLoaded", () => {
	load();
});
