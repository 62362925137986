import { WebGLRenderer, Scene, PerspectiveCamera, Raycaster, TextureLoader, Vector2, Vector3, Color, Mesh, SphereGeometry, MeshBasicMaterial, BackSide, EventDispatcher, MOUSE, Quaternion, Spherical } from 'three';
import ThreeTrackballControls from 'three-trackballcontrols';
import OrbitControlsWrapper from 'three-orbit-controls';
import FlyControlsWrapper from 'three-fly-controls';
import { parseToRgb, opacify } from 'polished';
import TWEEN from '@tweenjs/tween.js';
import accessorFn from 'accessor-fn';
import Kapsule from 'kapsule';

function styleInject(css, ref) {
  if (ref === void 0) ref = {};
  var insertAt = ref.insertAt;

  if (!css || typeof document === 'undefined') {
    return;
  }

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css = ".scene-nav-info {\n  bottom: 5px;\n  width: 100%;\n  text-align: center;\n  color: slategrey;\n  opacity: 0.7;\n  font-size: 10px;\n}\n\n.scene-tooltip {\n  color: lavender;\n  font-size: 15px;\n}\n\n.scene-nav-info, .scene-tooltip {\n  position: absolute;\n  font-family: sans-serif;\n  pointer-events: none;\n}";
styleInject(css);

var three = window.THREE ? window.THREE // Prefer consumption from global THREE, if exists
: {
  WebGLRenderer: WebGLRenderer,
  Scene: Scene,
  PerspectiveCamera: PerspectiveCamera,
  Raycaster: Raycaster,
  TextureLoader: TextureLoader,
  Vector2: Vector2,
  Vector3: Vector3,
  Color: Color,
  Mesh: Mesh,
  SphereGeometry: SphereGeometry,
  MeshBasicMaterial: MeshBasicMaterial,
  BackSide: BackSide,
  EventDispatcher: EventDispatcher,
  MOUSE: MOUSE,
  Quaternion: Quaternion,
  Spherical: Spherical
};
var ThreeOrbitControls = OrbitControlsWrapper(three);
var ThreeFlyControls = (FlyControlsWrapper(three), three.FlyControls);
var threeRenderObjects = Kapsule({
  props: {
    width: {
      "default": window.innerWidth,
      onChange: function onChange(width, state, prevWidth) {
        isNaN(width) && (state.width = prevWidth);
      }
    },
    height: {
      "default": window.innerHeight,
      onChange: function onChange(height, state, prevHeight) {
        isNaN(height) && (state.height = prevHeight);
      }
    },
    backgroundColor: {
      "default": '#000011'
    },
    backgroundImageUrl: {},
    onBackgroundImageLoaded: {},
    showNavInfo: {
      "default": true
    },
    skyRadius: {
      "default": 50000
    },
    objects: {
      "default": []
    },
    postProcessingComposer: {
      triggerUpdate: false
    },
    enablePointerInteraction: {
      "default": true,
      onChange: function onChange(_, state) {
        // Reset hover state
        state.hoverObj = null;
        if (state.toolTipElem) state.toolTipElem.innerHTML = '';
      },
      triggerUpdate: false
    },
    lineHoverPrecision: {
      "default": 1,
      triggerUpdate: false
    },
    hoverOrderComparator: {
      "default": function _default() {
        return -1;
      },
      triggerUpdate: false
    },
    // keep existing order by default
    tooltipContent: {
      triggerUpdate: false
    },
    hoverDuringDrag: {
      "default": false,
      triggerUpdate: false
    },
    onHover: {
      "default": function _default() {},
      triggerUpdate: false
    },
    onClick: {
      "default": function _default() {},
      triggerUpdate: false
    },
    onRightClick: {
      triggerUpdate: false
    }
  },
  methods: {
    tick: function tick(state) {
      if (state.initialised) {
        state.controls.update && state.controls.update();
        state.postProcessingComposer ? state.postProcessingComposer.render() // if using postprocessing, render only the output of the
        : state.renderer.render(state.scene, state.camera);

        if (state.enablePointerInteraction) {
          // Update tooltip and trigger onHover events
          var topObject = null;

          if (state.hoverDuringDrag || !state.controlsDragging) {
            var raycaster = new three.Raycaster();
            raycaster.linePrecision = state.lineHoverPrecision;
            raycaster.setFromCamera(state.mousePos, state.camera);
            var intersects = raycaster.intersectObjects(state.objects, true).map(function (_ref) {
              var object = _ref.object;
              return object;
            }).sort(state.hoverOrderComparator);
            topObject = intersects.length ? intersects[0] : null;
          }

          if (topObject !== state.hoverObj) {
            state.onHover(topObject, state.hoverObj);
            state.toolTipElem.innerHTML = topObject ? accessorFn(state.tooltipContent)(topObject) || '' : '';
            state.hoverObj = topObject;
          }
        }

        TWEEN.update(); // update camera animation tweens
      }

      return this;
    },
    cameraPosition: function cameraPosition(state, position, lookAt, transitionDuration) {
      var camera = state.camera; // Setter

      if (position && state.initialised) {
        var finalPos = position;
        var finalLookAt = lookAt || {
          x: 0,
          y: 0,
          z: 0
        };

        if (!transitionDuration) {
          // no animation
          setCameraPos(finalPos);
          setLookAt(finalLookAt);
        } else {
          var camPos = Object.assign({}, camera.position);
          var camLookAt = getLookAt();
          new TWEEN.Tween(camPos).to(finalPos, transitionDuration).easing(TWEEN.Easing.Quadratic.Out).onUpdate(setCameraPos).start(); // Face direction in 1/3rd of time

          new TWEEN.Tween(camLookAt).to(finalLookAt, transitionDuration / 3).easing(TWEEN.Easing.Quadratic.Out).onUpdate(setLookAt).start();
        }

        return this;
      } // Getter


      return Object.assign({}, camera.position, {
        lookAt: getLookAt()
      }); //

      function setCameraPos(pos) {
        var x = pos.x,
            y = pos.y,
            z = pos.z;
        if (x !== undefined) camera.position.x = x;
        if (y !== undefined) camera.position.y = y;
        if (z !== undefined) camera.position.z = z;
      }

      function setLookAt(lookAt) {
        state.controls.target = new three.Vector3(lookAt.x, lookAt.y, lookAt.z);
      }

      function getLookAt() {
        return Object.assign(new three.Vector3(0, 0, -1000).applyQuaternion(camera.quaternion).add(camera.position));
      }
    },
    renderer: function renderer(state) {
      return state.renderer;
    },
    scene: function scene(state) {
      return state.scene;
    },
    camera: function camera(state) {
      return state.camera;
    },
    controls: function controls(state) {
      return state.controls;
    },
    tbControls: function tbControls(state) {
      return state.controls;
    } // to be deprecated

  },
  stateInit: function stateInit() {
    return {
      scene: new three.Scene(),
      camera: new three.PerspectiveCamera()
    };
  },
  init: function init(domNode, state, _ref2) {
    var _ref2$controlType = _ref2.controlType,
        controlType = _ref2$controlType === void 0 ? 'trackball' : _ref2$controlType,
        _ref2$rendererConfig = _ref2.rendererConfig,
        rendererConfig = _ref2$rendererConfig === void 0 ? {} : _ref2$rendererConfig,
        _ref2$waitForLoadComp = _ref2.waitForLoadComplete,
        waitForLoadComplete = _ref2$waitForLoadComp === void 0 ? true : _ref2$waitForLoadComp;
    // Wipe DOM
    domNode.innerHTML = ''; // Add relative container

    domNode.appendChild(state.container = document.createElement('div'));
    state.container.style.position = 'relative'; // Add nav info section

    state.container.appendChild(state.navInfo = document.createElement('div'));
    state.navInfo.className = 'scene-nav-info';
    state.navInfo.textContent = {
      orbit: 'Left-click: rotate, Mouse-wheel/middle-click: zoom, Right-click: pan',
      trackball: 'Left-click: rotate, Mouse-wheel/middle-click: zoom, Right-click: pan',
      fly: 'WASD: move, R|F: up | down, Q|E: roll, up|down: pitch, left|right: yaw'
    }[controlType] || '';
    state.navInfo.style.display = state.showNavInfo ? null : 'none'; // Setup tooltip

    state.toolTipElem = document.createElement('div');
    state.toolTipElem.classList.add('scene-tooltip');
    state.container.appendChild(state.toolTipElem); // Capture mouse coords on move

    state.mousePos = new three.Vector2();
    state.mousePos.x = -2; // Initialize off canvas

    state.mousePos.y = -2;
    state.container.addEventListener("mousemove", function (ev) {
      if (state.enablePointerInteraction) {
        // update the mouse pos
        var offset = getOffset(state.container),
            relPos = {
          x: ev.pageX - offset.left,
          y: ev.pageY - offset.top
        };
        state.mousePos.x = relPos.x / state.width * 2 - 1;
        state.mousePos.y = -(relPos.y / state.height) * 2 + 1; // Move tooltip

        state.toolTipElem.style.top = "".concat(relPos.y, "px");
        state.toolTipElem.style.left = "".concat(relPos.x, "px");
        state.toolTipElem.style.transform = "translate(-".concat(relPos.x / state.width * 100, "%, 21px)"); // adjust horizontal position to not exceed canvas boundaries
      }

      function getOffset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {
          top: rect.top + scrollTop,
          left: rect.left + scrollLeft
        };
      }
    }, false); // Handle click events on objs

    state.container.addEventListener('click', function (ev) {
      if (state.ignoreOneClick) {
        state.ignoreOneClick = false; // because of controls end event

        return;
      }

      state.onClick(state.hoverObj || null, ev); // trigger background clicks with null
    }, false); // Handle right-click events

    state.container.addEventListener('mouseup', function (ev) {
      if (ev.button === 2 && state.onRightClick) {
        if (state.ignoreOneClick) {
          state.ignoreOneClick = false; // because of controls end event

          return;
        }

        state.onRightClick(state.hoverObj || null, ev);
      }
    }, false); // Setup renderer, camera and controls

    state.renderer = new three.WebGLRenderer(Object.assign({
      antialias: true,
      alpha: true
    }, rendererConfig));
    state.renderer.setPixelRatio(window.devicePixelRatio);
    state.container.appendChild(state.renderer.domElement); // configure controls

    state.controls = new {
      trackball: ThreeTrackballControls,
      orbit: ThreeOrbitControls,
      fly: ThreeFlyControls
    }[controlType](state.camera, state.renderer.domElement);

    if (controlType === 'fly') {
      state.controls.movementSpeed = 2.5;
    }

    if (controlType === 'trackball' || controlType === 'orbit') {
      state.controls.minDistance = 0.1;
      state.controls.addEventListener('start', function () {
        return state.controlsEngaged = true;
      });
      state.controls.addEventListener('change', function () {
        if (state.controlsEngaged) {
          state.controlsDragging = true;
          state.ignoreOneClick = true;
        }
      });
      state.controls.addEventListener('end', function () {
        state.controlsEngaged = false;
        state.controlsDragging = false;
      });
    }

    state.renderer.setSize(state.width, state.height);
    state.camera.aspect = state.width / state.height;
    state.camera.updateProjectionMatrix();
    state.camera.position.z = 1000; // add sky

    state.scene.add(state.skysphere = new three.Mesh());
    state.skysphere.visible = false;
    state.loadComplete = state.scene.visible = !waitForLoadComplete;
    window.scene = state.scene;
  },
  update: function update(state, changedProps) {
    // resize canvas
    if (state.width && state.height && (changedProps.hasOwnProperty('width') || changedProps.hasOwnProperty('height'))) {
      state.container.style.width = state.width;
      state.container.style.height = state.height;
      state.renderer.setSize(state.width, state.height);
      state.camera.aspect = state.width / state.height;
      state.camera.updateProjectionMatrix();
    }

    if (changedProps.hasOwnProperty('skyRadius') && state.skyRadius) {
      state.controls.hasOwnProperty('maxDistance') && (state.controls.maxDistance = state.skyRadius);
      state.camera.far = state.skyRadius * 2.5;
      state.camera.updateProjectionMatrix();
      state.skysphere.geometry = new three.SphereGeometry(state.skyRadius);
    }

    if (changedProps.hasOwnProperty('backgroundColor')) {
      var alpha = parseToRgb(state.backgroundColor).alpha;
      if (alpha === undefined) alpha = 1;
      state.renderer.setClearColor(new three.Color(opacify(1, state.backgroundColor)), alpha);
    }

    if (changedProps.hasOwnProperty('backgroundImageUrl')) {
      if (!state.backgroundImageUrl) {
        state.skysphere.visible = false;
        state.skysphere.material.map = null;
        !state.loadComplete && finishLoad();
      } else {
        new three.TextureLoader().load(state.backgroundImageUrl, function (texture) {
          state.skysphere.material = new three.MeshBasicMaterial({
            map: texture,
            side: three.BackSide
          });
          state.skysphere.visible = true; // triggered when background image finishes loading (asynchronously to allow 1 frame to load texture)

          state.onBackgroundImageLoaded && setTimeout(state.onBackgroundImageLoaded);
          !state.loadComplete && finishLoad();
        });
      }
    }

    changedProps.hasOwnProperty('showNavInfo') && (state.navInfo.style.display = state.showNavInfo ? null : 'none');

    if (changedProps.hasOwnProperty('objects')) {
      (changedProps.objects || []).forEach(function (obj) {
        return state.scene.remove(obj);
      }); // Clear the place

      state.objects.forEach(function (obj) {
        return state.scene.add(obj);
      }); // Add to scene
    } //


    function finishLoad() {
      state.loadComplete = state.scene.visible = true;
    }
  }
});

export default threeRenderObjects;
